<!--
 * @LastEditTime: 2022年8月27日 15:18:09
 * @Description: 定时任务
 * @FilePath: /dataview-next/src/views/objects/timedTask.vue
 * @Date: 2022-1-20 17:42:05
 * @Author: lw
 * @LastEditors: lw
-->

<template>
  <div class="h-full p-5" style="height: calc(100% - 40px)">
    <div style="float: left">
      <el-input
          style="float: right; width: 300px"
          v-model="search"
          size="small"
          placeholder="输入关键字搜索"
      />
    </div>
    <div class="text-right">
      <el-button size="small" type="primary" @click="add">新增</el-button>
    </div>
    <div style="height: calc(100% - 84px)" class="scroller">
      <el-table
          v-loading="tableLoading"
          :data="tableData.filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()))"
          border
          class="mt-5"
          height="100%"
          style="width: 100%"
      >
        <el-table-column label="ID" width="80" prop="id"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="上次执行时间" prop="last_execute_time"></el-table-column>
        <!--        <el-table-column label="下次执行时间" prop="next_execute_time"></el-table-column>-->
        <el-table-column label="创建时间" width="160" prop="created_at"></el-table-column>
        <el-table-column align="right" width="220" label="操作">

          <template slot-scope="scope">
            <span class="operation-primary" @click="execute(scope.row)">执行</span>
            <span class="operation-primary" @click="log(scope.row)">日志</span>
            <span class="operation-primary" @click="show(scope.$index, scope.row)">详情</span>
            <span class="operation-primary" @click="edit(scope.$index, scope.row)">编辑</span>

            <el-popconfirm class="operation-primary" title="是否确定删除定时任务？" @confirm="del(scope.$index, scope.row)">
              <span slot="reference" class="operation-primary">删除</span>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
    </div>

    <b-modal
        ref="elmodal"
        mode="drawer"
        title="定时任务"
        v-if="addModal"
        :show="addModal"
        @sure="sure"
        @cancel="addModal = false"
    >
      <el-form
          slot="content"
          ref="addForm"
          :rules="rules"
          :model="addForm"
          label-width="120px"
          :disabled="formFlag"
      >
        <el-form-item label="中文名称" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="task_type">
          <el-select class="w-full" clearable v-model="addForm.task_type" placeholder="请选择">
            <el-option
                v-for="item in timedTaskType"
                :key="item.value"
                :label="item.name"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="月" prop="month" v-if="[8].includes(addForm.task_type)">
          <el-select class="w-full" clearable v-model="addForm.month" placeholder="请选择">
            <el-option
                v-for="item in Array(12).fill(1).map((v,k)=>k+1)"
                :key="item"
                :label="item+'月'"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="周" prop="week" v-if="[6].includes(addForm.task_type)">
          <el-select class="w-full" clearable v-model="addForm.week" placeholder="请选择">
            <el-option
                v-for="item in Array(7).fill(1).map((v,k)=>k+1)"
                :key="item"
                :label="'周'+item"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="天" prop="hour" v-if="[3,7,8].includes(addForm.task_type)">
          <el-input-number v-model="addForm.day" :min="1" :max="31"></el-input-number>
        </el-form-item>

        <!--        -->
        <el-form-item label="小时" prop="hour" v-if="[2,3,5,6,7,8].includes(addForm.task_type)">
          <el-select class="w-full" clearable v-model="addForm.hour" placeholder="请选择">
            <el-option
                v-for="item in hourOptionData"
                :key="item"
                :label="item+'小时'"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="分钟" prop="minute" v-if="addForm.task_type">
          <el-select class="w-full" clearable v-model="addForm.minute" placeholder="请选择">
            <el-option
                v-for="item in minuteOptionData"
                :key="item"
                :label="item+'分钟'"
                :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="驱动类型" prop="task_run_type">
          <el-select class="w-full" clearable v-model="addForm.task_run_type" placeholder="请选择驱动类型">
            <el-option v-for="item in taskRunType" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="addForm.task_run_type" label="对象" prop="object_uuid">
          <object-select v-model="addForm.object_uuid"></object-select>
        </el-form-item>


        <el-form-item v-if="addForm.task_run_type==1" label="视图" prop="view_uuid">
          <view-select v-model="addForm.view_uuid" :object_uuid="addForm.object_uuid"></view-select>
        </el-form-item>

        <el-form-item v-if="addForm.task_run_type" label="事件" prop="event_uuid">
          <blue-print-event-select
              v-model="addForm.event_uuid"
              :object_uuid="addForm.object_uuid"
          ></blue-print-event-select>
        </el-form-item>

        <!--        <el-form-item v-if="addForm.task_run_type==2" label="行为" prop="behavior_data">-->
        <!--          <BehaviorsForm-->
        <!--              ref="behaviorForm"-->
        <!--              @addFn="saveBehavior"-->
        <!--              :obuuid="addForm.object_uuid"-->
        <!--              :formData="addForm.behavior_data"-->
        <!--          ></BehaviorsForm>-->
        <!--        </el-form-item>-->

      </el-form>
    </b-modal>

    <el-drawer title="执行日志" :visible.sync="drawer">
      <el-table :data="logsData" stripe style="width: 100%">
        <el-table-column prop="execute_time" label="执行时间"></el-table-column>
        <el-table-column prop="object_uuid" label="执行对象uuid"></el-table-column>
        <el-table-column prop="data_id" label="数据id"></el-table-column>
      </el-table>
      <el-pagination
          style="float: right; margin-top: 8px"
          v-if="logsTotal !== null"
          background
          @current-change="handleCurrentChange"
          :current-page="logsCurrentPage"
          :page-size="logsSize"
          layout="total, prev, pager, next"
          :total="logsTotal"
      >
      </el-pagination>
    </el-drawer>

  </div>
</template>

<script>
import object from "@/api/objects";
import obOpMixin from "./objectOperationMixin.js";
import ObjectSelect from "../../components/metaSelect/ObjectSelect";
import ViewSelect from "../../components/metaSelect/ViewSelect";
//import BehaviorsForm from "../../views/objects/components/behaviorsForm.vue";
//import EventSelect from "../../components/metaSelect/EventSelect";
import BluePrintEventSelect from "../../components/metaSelect/BluePrintEventSelect";
import {Popconfirm, InputNumber} from "element-ui";

export default {
  name: "timedTask",
  mixins: [obOpMixin],
  components: {
    ViewSelect,
    ObjectSelect,
    BluePrintEventSelect,
    //BehaviorsForm,
    'el-popconfirm': Popconfirm,
    'el-input-number': InputNumber
  },
  data() {
    return {
      tableData: [],
      logsData: [],
      logDetailData: null,
      logsTotal: 0,
      logsSize: 15,
      logsCurrentPage: 1,
      timedTaskType: [
        {name: 'N分钟', value: 1},
        {name: 'N小时', value: 2},
        //{name: 'N天', value: 3},
        //{name: '每小时', value: 4},
        {name: '每天', value: 5},
        {name: '每周', value: 6},
        {name: '每月', value: 7},
        {name: '每年', value: 8},
      ],
      // monthsData: Array(12).fill(1).map((v, k) => {
      //   return {name: (k + 1) + '月', value: k + 1}
      // }),
      // weeksData: Array(7).fill(1).map((v, k) => {
      //   return {name: '周' + (k + 1), value: k + 1}
      // }),
      minuteOptionData: [],
      hourOptionData: [],
      taskRunType: [
        {name: '数据驱动', value: 1},
        {name: '对象驱动', value: 2}
      ],
      search: "",
      tableLoading: false,
      addModal: false,
      formFlag: false,
      drawer: false,
      modalType: 0,
      addForm: {
        name: '',
        task_type: '',
        month: '',
        week: '',
        day: '',
        hour: '',
        minute: '',
        task_run_type: '',
        object_uuid: '',
        view_uuid: '',
        event_uuid: '',
      },
      rules: {
        name: [{required: true, message: "请输入", trigger: "blur"}],
        task_type: [{required: true, message: "请选择任务类型", trigger: "change"}],
        object_uuid: [{required: true, message: "请选择对象", trigger: "change"}],
        task_run_type: [{required: true, message: "请选择驱动类型", trigger: "change"}],
      },
      modalObj: null,
    };
  },
  computed: {
    taskType() {
      return this.addForm.task_type
    },
  },
  watch: {
    taskType: function (newval) {
      switch (newval) {
        case 1:
          this.minuteOptionData = [3, 5, 10, 15, 30]
          break
        case 2:
          this.hourOptionData = [1, 2, 3, 4, 6, 12]
          this.minuteOptionData = Array(60).fill(1).map((v, k) => k)
          break
        default:
          this.minuteOptionData = Array(60).fill(1).map((v, k) => k)
          this.hourOptionData = Array(24).fill(1).map((v, k) => k)
      }
    }
  },
  methods: {
    // saveBehavior(data = null) {
    //   this.addForm.behavior_data = data;
    // },
    sure() {
      // if (this.addForm.task_run_type === 2) {
      //   // 对象驱动 获取行为类配置
      //   this.$refs.behaviorForm.commit();
      // }

      this.$refs.addForm.validate((valid) => {
        if (valid) {
          if (this.modalType === 1) {
            this.modalObj = object.addTimedTask(this.addForm);
          } else if (this.modalType === 2) {
            this.modalObj = object.editTimedTask(this.addForm.uuid, this.addForm);
          } else {
            this.$refs.elmodal.loading = false;
            return;
          }

          this.modalObj.then((res) => {
            if (res.data.code === 200) {
              this.addModal = false;
              this.$refs.addForm.resetFields();
              this.getDatas();
            } else {
              this.$refs.elmodal.loading = false;
            }
          });
        } else {
          this.$refs.elmodal.loading = false;
        }
      });
    },
    show(index, row) {
      this.addForm = row;
      this.addModal = true;
      this.formFlag = true;
      this.modalType = 0;
    },
    add() {
      this.addModal = true;
      this.formFlag = false;
      this.modalType = 1;
      this.addForm = {
        name: "",
        task_type: "",
        week: "",
        day: "",
        hour: "",
        minute: "",
        object_uuid: "",
        view_uuid: "",
        event_uuid: "",
      };
    },
    edit(index, row) {
      this.addForm = row;
      this.addModal = true;
      this.formFlag = false;
      this.modalType = 2;
    },
    del(index, row) {
      object.delTimedTask(row.uuid).then((res) => {
        if (res.data.code === 200) {
          this.getDatas();
        }
      });
    },
    execute(row) {
      object.manualExecuteTimedTask(row.uuid).then((res) => {
        if (res.data.code === 200) {
          this.getDatas();
        }
      });
    },
    log(row) {
      this.drawer = true;
      this.logDetailData = row;
      this.openLogs();
    },
    handleCurrentChange(page) {
      this.openLogs(page);
    },
    openLogs(page = 1) {
      object.logsTimedTask(this.logDetailData.uuid, page).then((res) => {
        if (res.data.code == 200) {
          if (res.data.data.data) {
            this.logsTotal = res.data.data.total;
            this.logsData = res.data.data.data;
          } else {
            this.logsTotal = null;
            this.logsData = res.data.data;
          }
        }
      });
    },
    getDatas() {
      this.tableLoading = true;
      object.getTimedTasks().then((res) => {
        this.tableData = []
        this.tableLoading = false;
        this.$nextTick(() => {
          this.tableData = res.data.data;
        })
      });
    },
    getData() {
      this.getDatas();
    },
  },
  mounted() {
    this.getDatas();
  },
};
</script>
