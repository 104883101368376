<!--
 * @LastEditTime: 2022年8月27日 15:18:05
 * @Description: 蓝图事件选择器
 * @FilePath: /dwh_front/src/components/metaSelect/BluePrintEventSelect.vue
 * @Date: 2022年6月22日 17:32:47
 * @Author: lw
 * @LastEditors: lw
-->

<template>
  <el-select
      :multiple="multiple"
      filterable
      allow-create
      v-model="selectValue"
      style="width: 100%"
      v-loading="selectLoading"
      default-first-option
      element-loading-spinner="el-icon-loading"
  >
    <el-option
        v-for="(item, index) in selectData"
        :key="index"
        :label="item.data.name"
        :value="item.uuid"
    >
    </el-option>
  </el-select>
</template>

<script>
import objects from "@/api/objects";

export default {
  name: "BluePrintEventSelect",
  props: {
    object_uuid: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    object_uuid() {
      this.getSelectData();
    },
    selectValue() {
      this.$emit("input", this.selectValue);
    },
    value(val, oldVal) {
      if (val && !oldVal && !this.selectValue) {
        this.selectValue = val;
      }
    },
  },
  data() {
    return {
      selectData: [],
      selectValue: "",
      selectLoading: true,
    };
  },
  methods: {
    getSelectData() {
      this.selectLoading = true;
      let search = [];
      if (this.object_uuid) {
        search.push({
          code: "object_uuid",
          ruleType: "eq",
          value: this.object_uuid,
        });
      } else {
        this.selectData = []
        this.selectLoading = false;
        return
      }

      // 重新请求事件列表数据
      objects.mapi
          .common({
            __method_name__: "dataList",
            object_uuid: "object629eac5f15e0a",
            view_uuid: "view62b2dbc188c49",
            search: search,
            transcode: 0,
          })
          .then((res) => {
            this.selectLoading = false;
            if (res.data.code == 200) {
              this.selectData = res.data.data;
            } else {
              this.selectData = [];
            }
          })
          .catch(() => {
            this.selectLoading = false;
            this.selectData = [];
          });
    },
  },
  mounted() {
    this.getSelectData();
    this.selectValue = this.value
  },
};
</script>
